import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  idusuarios: number = parseInt(localStorage.getItem("idusuarios"));

  constructor() { }

  ngOnInit() {
    console.log(this.idusuarios);
  }

}
